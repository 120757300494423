<template>
    <div>
        <Header />
        <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
        <div class="container">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-5">
                                <div class="product-detail">
                                    <div class="tab-content" id="v-pills-tabContent">
                                        <div class="tab-pane fade active show" id="product-4" role="tabpanel">
                                            <div class="product-img">
                                                <img src="https://kmcorporate.com/wp-content/uploads/2021/06/Lynx-DS5-davanti-rev.1-210611-1536x1045.jpg"
                                                    alt="img-4" class="img-fluid mx-auto d-block" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- end product img -->
                            </div>
                            <div class="col-xl-7">
                                <div class="mt-4">

                                    <p style="font-size: 16px; color: black; font-weight: 400">

                                        Lynx DS is the new range of automatic solutions for standard cabling with high
                                        performance and reliability.
                                    </p>
                                </div>
                                <br />
                                <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                    TECHNICAL FEATURES
                                </h1>
                                <br />
                                <h3 style="color: black; font-weight: 800">Wire section</h3>

                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    0.13 - 6 mm / maximum Ø: 5.3 mm
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Cable feeding speed</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    6 m / s
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">
                                    Stripping length
                                </h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    0 - 15mm side 1 (optional 30mm) / 0 - 15mm side 2
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Minimum length of double crimped cable</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    60 mm
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Minimum cable length without crimping</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    40mm
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Maximum cable length</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    99 m
                                </p>



                                <br />
                                <h3 style="color: black; font-weight: 800">Tolerance</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    ± 1 mm up to 3 m
                                </p>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    ± 0.2% from 3 to 99mm / resolution 0.1mm
                                </p>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    CQC Crimping control system
                                </p>
                                <br />


                                <h1 style="color: black; font-weight: 800" class="font-size-20 mb-3">
                                    TECHNICAL DATA
                                </h1>
                                <br />
                                <h3 style="color: black; font-weight: 800">Power supply</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    3PH + N + PE 400 V 50 Hz
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Pneumatic supply</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    6-7 bar (7 bar at maximum supply)
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Electric power</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    5 kW
                                </p>

                                <br />
                                <h3 style="color: black; font-weight: 800">Dimensions</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    L 3616 mm x P 1594 mm x H 1900 mm
                                </p>


                                <br />
                                <h3 style="color: black; font-weight: 800">Weight</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    1200 kg
                                </p>

                                <br />

                                <h3 style="color: black; font-weight: 800">OTHER ACCESSORIES AVAILABLE ARE</h3>
                                <p style="font-size: 16px; color: black; font-weight: 400">
                                    Inkjet marker implementation
                                    Dual device Seam control
                                    systems
                                    Dual device Seam
                                    control systems Band cutter Split Cycle paper rewinder Specific cable processing
                                    Order List Stacker extension 2 meters. Ready for Industry 4.0 Tinning unit Fluxing
                                    unit Linking unit
                                </p>

                                <br />

                                <p style="font-size: 14px; color: black; font-weight: 400">
                                    Data and measurements are indicative and subject to change
                                    without notice. Some particular types of cables may not be
                                    machined even though they fall within the range of sections
                                    indicated. KM Corporate will still be happy to carry out tests
                                    and supply wire-worked samples
                                </p>
                            </div>
                        </div>
                        <!-- end row -->
                        <br>
                        <br>
                        <br>
                        <div class="row">
                            <h1 style="color: black; font-weight: 800;margin-left:40px;" class="font-size-20 mb-3">
                                Gallery
                            </h1>
                        </div>
                        <!-- end row -->
                        <div class="row">
                            <div style="margin-left:60px;">
                                <vue-picture-swipe :items="items"></vue-picture-swipe>
                            </div>
                        </div>
                        <!-- end row -->
                    </div>
                </div>
                <!-- end card -->
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
    components: {
        Header,

        Footer,
    },
    data() {
        return {
            items: [{
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/Lynx-DS5-rev.1-210607-1-150x150.png',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/Lynx-DS5-rev.1-210607-1-150x150.png',
                w: 1200,
                h: 900,
                alt: 'some numbers on a grey background' // optional alt attribute for thumbnail image
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/08/LYNX-PIANTINA-rev.-3-210802-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/08/LYNX-PIANTINA-rev.-3-210802-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-025-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-025-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-036-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-036-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-037-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-037-150x150.jpg',
                w: 1200,
                h: 900
            },
            {
                src: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-039-150x150.jpg',
                thumbnail: 'https://kmcorporate.com/wp-content/uploads/2021/06/KM-039-150x150.jpg',
                w: 1200,
                h: 900
            },

            ]
        };

    }
};
</script>